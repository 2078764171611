.LayoutSearchResults {
    max-width: 1170px;
    margin: 0 auto 100px auto;
}
.Search_modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 40px;
}
.Search_modal_title{
    font-size: 32px;
}
.Search_modal_alert{
    font-size: 22px;
    text-align: center;
}
@media (max-width: 1370px) {
    .LayoutSearchResults {
        max-width: 1100px;
    }
}
@media (max-width: 1270px) {
    .LayoutSearchResults {
        max-width: 1000px;
    }
}
@media (max-width: 1170px) {
    .LayoutSearchResults {
        max-width: 900px;
    }
}
.LayoutSearchResults__header {
    min-height: 90px;
    margin: 40px auto;
    background: #FFFFFF;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 1px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.LayoutSearchResults__logo {
    cursor: pointer;
    width: 130px;
    height: 70px;
    background: url('../../../assets/images/logo_no_text.png')center /contain no-repeat;
}
.LayoutSearchResults__logo--inactive {
    cursor: default;
}
.LayoutSearchResults__contactPhoneBlock {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    flex-basis: 25%;
}
.LayoutSearchResults__boldText {
    font-weight: bold;
    margin: 0;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #4D525B;
}
.LayoutSearchResults__boldText--center {
    text-align: center;
}
.LayoutSearchResults__headerHelperText {
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.01em;
    color: #828282;
    margin: 0;
    text-align: center;
}
.LayoutSearchResults__headerHelperText--left {
    text-align: left;
}

.LayoutSearchResults__idHeaderBlock {
    display: flex;
    flex-basis: 20%;
    flex-direction: column;
}
.LayoutSearchResults__NameBlock {
    display: flex;
    flex-basis: 30%;
    flex-direction: column;
}

.LayoutSearchResults__TabBlock {
    display: flex;
    flex-basis: 30%;
    justify-content: center;
}

.LayoutSearchResults__TabBlock_TableIcon, .LayoutSearchResults__TabBlock_AnalyticsIcon {
    padding: 2px;
    cursor: pointer;
}

.LayoutSearchResults__TabBlock > * {
    margin: 0 10px;
}

.Active__Icon {
    background-color: #F3F6F9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
}

.Inactive__Icon {
    background-color: #FFFFFF;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

.LayoutSearchResults__CompanyBlock {
    display: flex;
    flex-basis: 30%;
    margin: 0 10px 0 0;
    flex-direction: column;
}
.LayoutSearchResults__companyLogo {
    width: 100px;
    height: 80px;
}
.LayoutSearchResults__bigNotBoldText {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.01em;
    margin: 0;
    color: #4D525B;
    text-align: center;
}

.LayoutSearchResults__question {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    background: url('../../../assets/icons/ic_question.svg')6px 4px  no-repeat, url('../../../assets/icons/ic_question_frame.svg')center center no-repeat;
}
.LayoutSearchResults__question--modal {
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 217px;
    padding: 0 20px;
    border: 1px solid #E34A4E;
    background: white;
    border-radius: 4px;
}
.LayoutSearchResults__question--modal-text {
    color: #4D525B;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
}
.LayoutSearchResults__question:hover {
    background: url('../../../assets/icons/ic_question_red.svg')6px 4px  no-repeat, url('../../../assets/icons/ic_question_frame_red.svg')center center no-repeat;
}


.LayoutSearchResults__dateEngine {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.LayoutSearchResults__buttonsBlock {
    display: flex;
}
.LayoutSearchResults__btnDate {
    width: 93px;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #E34A4E;
    border-right: none;
    font-weight: bold;
    font-size: 12px;
    color: #E34A4E;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
}
.LayoutSearchResults__btnDate:hover {
    background: #E34A4E;
    color: white;
}
.LayoutSearchResults__btnDate--left {
    border-radius: 4px 0px 0px 4px;
}
.LayoutSearchResults__btnDate--right {
    border-right: 1px solid #E34A4E;
    border-radius: 0px 4px 4px 0px;
}
.LayoutSearchResults__idDateBlock {
    display: flex;
    align-items: center;
}
.LayoutSearchResults__hashKey {
    font-weight: bold;
    font-size: 14px;
    color: #E34A4E;
    margin-right: 7px;
}
.LayoutSearchResults__idInput {
    width: 166px;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #E34A4E;
    border-radius: 4px;
    padding: 2px;
    text-align: center;
    outline: none;
    margin: 0 8px 0 0;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #4D525B;
    font-size: 14px;
}
.LayoutSearchResults__idInput:focus{
    border: 0;
}
.LayoutSearchResults__idInput:focus::-webkit-input-placeholder {
    color: #4FC6E0;
    
}
.LayoutSearchResults__idInput:focus::-moz-placeholder {
    color: #4FC6E0;
}
.LayoutSearchResults__idInput:focus:-ms-input-placeholder {
    color: #4FC6E0;
}
.LayoutSearchResults__idInput:focus:-moz-placeholder {
    color: #4FC6E0;
}
.LayoutSearchResults__idInput::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: "Source Sans Pro";
    font-size: 11px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #BDBDBD;
  }
.LayoutSearchResults__idInput::-moz-placeholder { /* Firefox 19+ */
    font-family: "Source Sans Pro";
    font-size: 11px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #BDBDBD;
  }
.LayoutSearchResults__idInput:-ms-input-placeholder { /* IE 10+ */
    font-family: "Source Sans Pro";
    font-size: 11px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #BDBDBD;
  }
.LayoutSearchResults__idInput:-moz-placeholder { /* Firefox 18- */
    font-family: "Source Sans Pro";
    font-size: 11px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #BDBDBD;
  }
.LayoutSearchResults__chooseDateBlock {
    display: flex;
    align-items: center;
}
.LayoutSearchResults__dash {
    font-size: 20px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: #BDBDBD;
    margin: 0 3px;
}
.LayoutSearchResults__inputDate {
    width: 94px;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1em;
    color: #BDBDBD;
    display: flex;
    justify-content: center;
    align-items: center;
}


.LayoutSearchResults__footer {
    display: flex;
    align-items: center;
    max-width: 1170px;
    margin: 0 auto;
}
.LayoutSearchResults__footerText {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #4D525B;
    margin-right: 10px;
}
.LayoutSearchResults__filters {
    display: flex;
    align-items: center;
}
.LayoutSearchResults__resetText {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #4D525B;
    cursor: pointer;
    margin-left: 19px;
}
.LayoutSearchResults__resetText:hover {
    color: black;
}
.LayoutSearchResults__paginationBlock {
    display: flex;
    margin-left: auto;
}
.LayoutSearchResults__arrowBtn {
    width: 40px;
    height: 30px;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}
.LayoutSearchResults__btnDate_active{
    color: #fff;
    background: #E34A4E;
}
.LayoutSearchResults__arrowBtn--left {
    background: white url('../../../assets/icons/ic-arrow-pagination-left-gray.svg')center no-repeat;
}
.LayoutSearchResults__arrowBtn--right {
    background: white url('../../../assets/icons/ic-arrow-pagination-right-gray.svg')center no-repeat;
}
.LayoutSearchResults__arrowBtn--left:hover {
    background: #BDBDBD url('../../../assets/icons/ic-arrow-pagination-left-white.svg')center no-repeat;
}
.LayoutSearchResults__arrowBtn--right:hover {
    background: #BDBDBD url('../../../assets/icons/ic-arrow-pagination-right-white.svg')center no-repeat;
}
.LayoutSearchResults__arrowBtn--left:active {
    transform: scale(0.9);
}
.LayoutSearchResults__arrowBtn--right:active {
    transform: scale(0.9);
}
.LayoutSearchResults__pagesNumbersBlock {
    display: flex;
    align-items: center;
    margin-left: 30px;
    width: 260px;
}