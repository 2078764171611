.LogoSideBar, .LogoSideBar-faq {
    position: fixed;
    height: 100%;
    width: 60px;
    z-index: 0;
    background-color: #FFF;
}

.LogoSideBar {
    top: 0;
    left: 0;
}

.Sidebar__quit{
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}
@media (max-width: 425px) {
    .LogoSideBar {
        display: none;
    }
}

.sidebarColor {
    background-color: #4FC6E0;
    width: 6px;
    height: 100%;
}
.itsupport {
    position: absolute;
    bottom: 85px;
    left: 17px;
    background: url("/src/assets/images/itsupport-sidebar.svg") center no-repeat;
    height: 150px;
    width: 30px;
}
.logo {
    position: absolute;
    bottom: 30px;
    left: 12px;
    background: url("/src/assets/images/itsg_logo.svg") center no-repeat;
    background-size: 35px;
    width: 40px;
    height: 40px;
}

.invision {
    position: absolute;
    bottom: 45px;
    left: 14px;
    background: url("/src/assets/icons/invision_sidebar_logo.svg") center no-repeat;
    background-size: contain;
    height: 180px;
    width: 33px;
}

.sidebarColorInvision {
    background-color: #FF8B00;
    width: 6px;
    height: 100%;
}