.INVISION, .fill-inv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    min-height: 120px;
    /*background-color: white;*/
    border-radius: 8px;
    padding-left: 15px;
    cursor: pointer;
}

.INVISION-logo {
    width: 130px;
    height: 64px;
    background: url('../../../assets/images/inv_logo.svg')left no-repeat;
    background-size: 130px;
    margin-right: 10px;
}
.INVISION .classification {
    display: flex;
    flex-direction: column;
    width: 120px;
    padding-left: 25px;
    margin-right: 20px;
    border: 1px none #BDBDBD;
    border-left-style: solid;
}
.INVISION .classification p {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #BDBDBD;
}
.fill-inv {
    position: absolute;
    top: 0;
    width: 300px;
    background: linear-gradient(to left, transparent 50%, #CA3F3F 50%) right;
    background-size: 200%;
    transition: .25s ease-out;
}
.fill-inv:hover {
    background-position: left;
}
.fill-inv:hover .invHoverLogo-inv, .fill-inv:hover .probe-text {
    visibility: visible;
}
.fill-inv .invHoverLogo-inv {
    visibility: hidden;
    width: 140px;
    height: 40px;
    margin-right: 25px;
    background: url('../../../assets/images/invision_hover_logo.svg')center no-repeat;
}

.fill-inv .probe-text {
    visibility: hidden;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    text-align: center;
}

@media (max-width: 1280px) {
    .INVISION {
        background-size: 170px;
        min-height: 120px;
    }
}