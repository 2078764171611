.ChatName__wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.ChatName__name{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    
    color: #00ACCD;

}