.FormInputTextSecure_group{
    position: relative;
    background-color: transparent;
    margin: 25px 0;
}
.FormInputTextSecure{
    background: transparent;
    min-height: 44px;
    width: 100%;
    color: #4D525B;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    padding: 10px 30px;
    border: 1px solid #C0C0C0;
    border-radius: 4px;
    
    
}

.FormInputTextSecure_label{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #C0C0C0;

    position: absolute;
    left: 30px;
    top: 12px;
    transition: all .3s ease;
}
.FormInputTextSecure__show_text{
    -webkit-text-security: none; /* Для Safari и старых версий Chrome */
    text-security: none;
}
.FormInputTextSecure__hide_text{
    -webkit-text-security: disc; /* Для Safari и старых версий Chrome */
    text-security: disc;
}
.FormInputTextSecure_label{
    cursor:text;
}
.FormInputTextSecure::placeholder{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #C0C0C0;
}
.FormInputTextSecure:focus ~ .FormInputTextSecure_label,
.FormInputTextSecure:not(:placeholder-shown) ~ .FormInputTextSecure_label{
    padding: 0 3px;
    background: white;
    font-size: 16px;
    z-index: 1;
    color: black;
    top: -16px;
}
input:focus{
    outline: 1px solid #4FC6E0;
}
.FormInputTextSecure:focus{
    border: 1px solid #4FC6E0;
}
.FormInputTextSecure__password_icon{
    position: absolute;
    right: 10px;
    width: 28px;
    height: 20px;
    top: 14px;
    cursor: pointer;
}
.FormInputTextSecure__password_icon_show{
    position: absolute;
    right: 10px;
    width: 28px;
    height: 20px;
    top: 14px;
    mask-image: url("../../../../assets/icons/ic_showPass.svg");
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    transition: all .5s ease;
    background: #C0C0C0;
    
}
.FormInputTextSecure__password_icon:hover{
    background: #E34A4E;
}
.FormInputTextSecure__password_icon_hidden{
    position: absolute;
    right: 10px;
    width: 28px;
    height: 20px;
    top: 14px;
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-image: url("../../../../assets/icons/ic_pass_hidden.svg");
    transition: all .5s ease;
    background: #C0C0C0;
}
