
.skiptranslate{
    background-image: none !important;
}
.skiptranslate iframe{
    display: none;
    height: 0;
  
}
#google_translate_element{
    display: none !important;
}
.TranslatePage__wrapper{
    display: flex;
    justify-content: flex-end;
}