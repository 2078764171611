.react-tel-input {
    font-size: 15px;
    position: relative;
    width: 100%;
}

.react-tel-input :disabled {
    cursor: not-allowed
}

.react-tel-input * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}


.react-tel-input .form-control {
    position: relative;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    margin: 30px 0;
    color: #4D525B;
    background: #FFFFFF;
    outline: none;
    border: none;
}
.react-tel-input .flag-dropdown {
    display: none;
}

.react-tel-input .special-label {
    display: none;
    position: absolute;
    z-index: 1;
    font-size: 13px;
    left: 46px;
    top: -8px;
    background: #fff;
    padding: 0 2px;
    white-space: nowrap
}