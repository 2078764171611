.ReturnApplication__wrapper{
    max-width: 375px;
}
.ReturnApplication{
    max-width: 375px;
    background: #F3F6F9;
    padding: 13px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.ReturnApplication__input{
    font-family: Source Sans Pro;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 127.273% */
    letter-spacing: 0.11px;
    padding: 11px;
    background: white;
    border: 0;
    width: 100%;
    border-radius: 8px;
}
.ReturnApplication__input{
    outline: 0 !important;
}
.ReturnApplication__title{
    color: #4D525B;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.18px;
    border-radius: 8px;
}
.ReturnApplication__title_red{
    color: #C53437;
}
.ReturnApplication__description{
    color: #4D4D4F;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
    letter-spacing: 0.12px;
}
.ReturnApplication__reasons_list{
    display: flex;
    column-gap: 20px;
    row-gap: 10px;
    flex-wrap: wrap;
}
.ReturnApplication__reason{
    color: white;
    border-radius: 12px;
    background: #00ABCD;
    width: fit-content;
    padding: 3px 5px;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.12px;
    cursor: pointer;
    border: 0;
    position: relative;
    transition: all .3s ease;
}
.ReturnApplication__reason_disabled{
    background-color: #E0E0E0;
    color: #989898;
    text-align: center;
    border-color: #989898;
    cursor:not-allowed;
}
.ReturnApplication__reason:hover{
    padding-left: 20px;

}
.ReturnApplication__reason::after{
    content: "";
    opacity: 0;
    position: absolute;
    left: 8px;
    top: 110%;
    transform: translateY(-50%);
    transition: all .3s ease;
    background-image: url("../../../../assets/icons/select_reason.svg");
    width: 9px;
    height: 13px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.ReturnApplication__reason:disabled:hover{
    padding-left: 5px;
}
.ReturnApplication__reason:disabled:hover::after{
    display: none;
}
.ReturnApplication__reason:hover::after{
    opacity: 1;
    top: 50%;
}
.ReturnApplication__reason_selected{
    background-color: #C53036;
}
.ReturnApplication__reason_selected:hover{
    background-color: #E34A4E;
}
.ReturnApplication__reason_selected:hover::after{
    background-image: url("../../../../assets/icons/delete_reason.svg");

}
.ReturnApplication__reason_selected_green{
    background-color: #3CC13B;
}
.ReturnApplication__reason_selected_green:hover{
    background-color: #278126;
}
.ReturnApplication__reason_selected_green:hover::after{
    background-image: url("../../../../assets/icons/delete_reason.svg");

}
.goToApplication{
    padding: 10px 40px ;
    color: #FFF;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.12px;
    background: #E34A4E;
    border: 0;
    width: fit-content;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}

.NewApplication__button {
    width: 200px;
    height: 44px;
    background: white;
    border: none;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    background: #4FC6E0;
    border-radius: 4px;
    outline: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NewApplication__button:disabled {
    background: rgba(79, 198, 224, 0.5);
}

.NewApplication__button:active {
    transform: scale(0.99);
}

@media (max-width: 425px) {
    .NewApplication__button {
        margin-bottom: 20px;
    }
}