.ChatImageModal__wrapper{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 6;
}
.ChatImageModal__wrapper_active{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ChatImageModal__bg{
    
    background: #4D4D4F;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all .5s ease;
}
.ChatImageModal__bg_active{
    opacity: 0.4;
    pointer-events: all;
}
.ChatImageModal__modal{
    z-index: 7;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 80%;
    max-height: 80vh;
    height: auto;
}
.ChatImageModal__modal_active{
    animation: popup_animation 1s;
}

.ChatImageModal__files{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.ChatImageModal__modal_img{
    width: 100%;
    object-fit: contain;
    max-height: 100%;
}
@keyframes popup_animation {
    0%{
        transform: scale(0);
    }
    50%{
        transform: scale(1.1);
    }
    75%{
        transform: scale(.9);
    }
    100%{
        transform: scale(1);
    }
}