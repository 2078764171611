.Hint{
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #4D525B;
    border-radius: 50%;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.01em;
    
    color: #4D525B;
    cursor: pointer;
    margin-left: 20px;
}
.Hint:hover{
    background: #4FC6E0;
    color: white;
    border: 0;
}

.Hint__content{
    position: absolute;
    font-family: 'Source Sans Pro';
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-style: normal;
    bottom: 220%;
    left: -175%;
    
    padding: 11px;
    border: 1px solid #4FC6E0;
    background: white;
    min-width: 210px;
    border-radius: 8px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #4D525B;
    opacity: 0;
    display: none;
}
.Hint__content::before{
    content: "";
    width: 0;
    height: 0;
    right: 150px;
    top: 100%;
    position: absolute;
    border-right: 14px solid transparent;
    border-top: 20px solid #4FC6E0;
    border-left: 14px solid transparent;
}
.Hint__content::after{
    content: "";
    width: 0;
    height: 0;
    right: 151px;
    top: 100%;
    position: absolute;
    border-right: 13px solid transparent  ;
    border-top: 19px solid white;
    border-left: 13px solid transparent;
}

.Hint__content_active{
    opacity: 1;
    display: flex;
}