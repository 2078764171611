.FaqSidebar__background{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.0);
    transition: all .5s linear;

    pointer-events: none;
}
.FaqSidebar__background__active{
    pointer-events: all;
    background-color: rgba(0,0,0,.2);

}
.FaqSidebar__wrapper{
    display: flex;
    max-width: 1100px;
    width: 100%;
    min-height: 100%;
    position: fixed;
    z-index: 5;
    top: 0;
    transform: translateX();
    transition: all .5s ease;
    -ms-user-select: none; 
	-moz-user-select: none; 
	-webkit-user-select: none; 
	user-select: none; 
}
.FaqSidebar__leftbar{
    height: 100%;
    min-width: 80px;
    background-color: #00ACCD;
}
.FaqSidebar__wrapper{
    height: 100vh;
}
.FaqSidebar__body_wrapper{
    padding-right: 30px;
    background-color: #4FC6E0;
}
.FaqSidebar__body{
    background-color: #4FC6E0;
    height: 100%;
    overflow-y: auto;
    padding: 100px 60px;
}

.FaqSidebar__title{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.FaqSidebar__info{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    max-width: 780px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.FaqSidebar__control{
    background: #FFFFFF;
    min-width: 66px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.FaqSidebar__go_back{
    margin-top: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FaqSidebar__itsupport{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FaqSidebar__small_logo{
    width: 45px;
    height: 40px;
    margin: 20px 0;
    object-fit: contain;
}

.FaqSidebar__questions_short{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.FaqSidebar__shortQuestion{
    width: 49%;
    margin: 20px 0;
}

.FaqSidebar__longQuestion{
    width: 100%;
    margin: 20px 0;
}

.FaqSidebar__questions_long{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FaqSidebar__active{
    transform: translateX(0%);
    left: 0;
}
.FaqSidebar__unactive{

    left: -1027px;
}
@media(max-width: 1100px){
    .FaqSidebar__wrapper{
        max-width: 1024px;
        
    }
    .FaqSidebar__unactive{
        left: -955px;
    }
}


.FaqSidebar__body::-webkit-scrollbar {
    width: 20px;
    height: 90%;
    background-color: #F3F6F9;
    border: 20px transparent;

}
.FaqSidebar__body::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #00ACCD;
    right: 8px;
    min-width: 20px;
    border-radius: 20px;
    height: 140px;
    background-color: #00ACCD;
}

.FaqSidebar__body::-webkit-scrollbar-track{
    position: fixed;
    width: 4px;
    height: 90%;
    box-shadow: inset 0px 0px 0px 8px #4fc6e0;
    background-color: #F3F6F9;
    left: 63.5%
}

.FaqSidebar__go_back_icon{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.01em;
    
    color: #C53036;
}

.FaqSidebar__go_back_span{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;

    color: #4D525B;
}

.FaqSidebar__FAQ{
    font-size: 26px;
    line-height: 35px;
}

.FaqDepartment__table{
    width: 100%;
    border: 0.01em solid rgba(0,0,0,.2);
}
.FaqDepartment__row{
    display: flex;

}
.FaqDepartment__row > * {
    width: 50%;
    border: 0.01em solid rgba(0,0,0,.2);
    padding: 10px 20px;
}
.FaqDepartment__title{
    text-align: center;
}