.ChatAddFiles__wrapper{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 6;
}
.ChatAddFiles__wrapper_active{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ChatAddFiles__bg{
    
    background: #4D4D4F;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all .5s ease;
}
.ChatAddFiles__bg_active{
    opacity: 0.4;
    pointer-events: all;
}
.ChatAddFiles__modal{
    z-index: 7;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.11);
    border-radius: 11px;
    padding: 30px 70px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 50%;
}
.ChatAddFiles__modal_active{
    animation: popup_animation 1s;
}
.ChatAddFiles__modal_title{
    display: flex;
    gap: 20px;
    align-items: center;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    
    color: #4D525B;
    cursor: pointer;
    
}
.ChatAddFiles__modal_title:hover .ChatAddFiles__modal_title_text{
    color: #e34a4e;
}
.ChatAddFiles__modal_title:hover .ChatAddFiles__modal_title_icon > * > *{
    fill: #e34a4e;
}
.ChatAddFiles__modal_hint{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.01em;

    color: #4D525B;

}
.ChatAddFiles__files{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
@keyframes popup_animation {
    0%{
        transform: scale(0);
    }
    50%{
        transform: scale(1.1);
    }
    75%{
        transform: scale(.9);
    }
    100%{
        transform: scale(1);
    }
}