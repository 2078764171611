.Chat{
    height: 100%;
    width: 100%;
    margin: 0 auto;
    background: transparent;
    border-left: 1px solid #C0C0C0;
    border-right: 1px solid #C0C0C0;
    border-bottom: 1px solid #C0C0C0;
    border-radius: 0 0 4px 4px;
    min-height: 383px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.Chat__view{
    height: 337px;
    overflow-y: scroll;
    padding: 15px 60px;
    display: flex;
    flex-direction: column-reverse;
    scroll-behavior: smooth;
}
.Chat__view > *{
    margin: 20px 0;
}
.Chat__view::-webkit-scrollbar{
    width: 7px;
    background: #E8E8EC;
}
.Chat__view::-webkit-scrollbar-thumb{
    width: 7px;
    border-radius: 20px;
    background: #C0C0C0;
}
.Chat__input{
    width: 100%;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #4D525B;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 15px 60px;
    cursor: text;
    border-top: 1px solid #C0C0C0;;
    border-radius: 4px;
    position: relative;
}
.Chat__input:focus{
    border: 0;
}
.Chat__form{
    position: relative;
}
.Chat__submit{
    border: 0;
    background: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.Chat__submit_img{
    height: 40px;
}
.Chat__add_files{
    border: 0;
    background: 0;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.Chat__add_img{
    height: 20px;
    
}
.Chat__ended{
    background: white;
    padding: 25px 20px 36px 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 0 0 8px 8px;
}
.Chat__end_title{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.Chat__end_user{
    position: absolute;
    right: calc(100% + 10px);
    width: 25px;
    height: 25px;
}
.Chat__end_green{

    color: #3CC13B;
}
.Chat__end_grey{
    color: #4D525B;
}
.Chat__end_result_title{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
}
.Chat__end_result_text{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
}
