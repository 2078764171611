.PatchNotes{
    margin-top: 20px;
    max-height: 60vh;
    overflow-y: auto;
}

.PatchNotes {
    scrollbar-width: thin;
    scrollbar-color: #4B4C4D #FFFFFF;
}


.PatchNotes::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

.PatchNotes::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #FFFFFF;
}

.PatchNotes::-webkit-scrollbar-track:hover {
    background-color: #FCFCFC;
}

.PatchNotes::-webkit-scrollbar-track:active {
    background-color: #FCFCFC;
}

.PatchNotes::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #4B4C4D;
}

.PatchNotes::-webkit-scrollbar-thumb:hover {
    background-color: #4B4C4D;
}

.PatchNotes::-webkit-scrollbar-thumb:active {
    background-color: #4B4C4D;
}
.PatchNote__readall{
    color: #C0C0C0;
    font-size: 16px;
    letter-spacing: 0.16px;
    text-decoration-line: underline;
    background: none;
    border: 0;
    align-self: flex-end;
    cursor: pointer;
    transition: all .3s ease;
}
.PatchNote__readall:hover{
    color: #E34A4E;
}
.PatchNotes__wrapper{
    display: flex;
    flex-direction: column;

}
/* -------------------  */
.PatchNoteHeader{
    padding: 10px 20px;
    transition: all .3s ease;
}


.PatchNoteHeader__title{
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}
.PatchNoteHeader__date {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.PatchNoteHeader__unread{
    color: #E34A4E;
    border-left: 5px solid #E34A4E;
}

.PatchNoteHeader__read{
    color: #4D525B;
    border-left: 5px solid #4D525B;
}


 /* -------------------  */
.PatchNoteBody{
    padding: 10px 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    transition: all .3s ease;
}

.PatchNoteBody__unread{
    border-left: 5px solid #E34A4E;
}

.PatchNoteBody__read{
    border-left: 5px solid #4D525B;
}


.PatchNote__collapse .ant-collapse-item{
    padding: 10px 0;
    transition: all .3s ease;
}
.PatchNote__collapse .ant-collapse-item:hover .PatchNoteHeader{

    color: #4FC6E0 !important;
    border-color: #4FC6E0 !important;
}
.PatchNote__collapse .ant-collapse-item:hover .PatchNotes{
    border-color: #4FC6E0 !important;
}
.PatchNote__collapse .ant-collapse-content-box{
    padding: 0 16px !important; 
}
.PatchNote__collapse .ant-collapse-header{
    padding: 0 16px !important; 
}