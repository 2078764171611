.ITSupport, .fill-itsg {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    min-height: 120px;
    background-color: white;
    border-radius: 8px;
    padding-left: 25px;
    cursor: pointer;
}

.ITSupport-logo {
    width: 60px;
    height: 64px;
    background: url('../../../assets/images/itsg_logo.svg')left no-repeat;
    background-size: 60px;
}
.ITSupport .classification {
    display: flex;
    flex-direction: column;
    width: 120px;
    padding-left: 25px;
    border: 1px none #BDBDBD;
    border-left-style: solid;
}
.ITSupport .classification p {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #BDBDBD;
}
.fill-itsg {
    position: absolute;
    top: 0;
    width: 300px;
    background: linear-gradient(to left, transparent 50%, #CA3F3F 50%) right;
    background-size: 200%;
    transition: .25s ease-out;
}

.fill-itsg:hover {
    background-position: left;
}
.fill-itsg:hover .invHoverLogo-itsg {
    visibility: visible;
}
.fill-itsg .invHoverLogo-itsg {
    visibility: hidden;
    width: 140px;
    height: 60px;
    margin-right: 25px;
    background: url('../../../assets/images/itsg_hover_logo.svg')center no-repeat;
}

@media (max-width: 1280px) {
    .ITSupport {
        background-size: 170px;
        min-height: 120px;
    }
}