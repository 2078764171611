
input[type="radio"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
.RadioInput__group{
    display: flex;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
}

.RadioInput__label{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #4D525B;
}

.RadioInput{
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #D7D7D7;
    border-radius: 50%;
    margin: 0 10px;
}


.RadioInput__circle{
    width: 20px;
    height: 20px;
    margin-right: 20px;
    background: #FFFFFF;
    border: 2px solid #D7D7D7;
    border-radius: 50%;
}

.circle_active{
    width: 12px;
    height: 12px;
    margin: 2px;
    border-radius: 50%;
    background: #4FC6E0;
    border: 2px solid #4FC6E0;
    border-radius: 10px;
}