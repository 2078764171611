.TranslateSelect{
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    border-radius: 4px;
    min-height: 24px;
    position: relative;

}
.TranslateSelect__value{
    border: 0;
    background: 0;
}
.TranslateSelect{
    -webkit-user-modify: read-write-plaintext-only;
}