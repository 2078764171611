p {
  margin: 0;
}

.Comment {
  background: #c0c0c0;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  min-height: 100px;
  width: 400px;
  margin: 10px 0;
}

.AccessApplicationDetails {
  width: 100%;
  /*min-height: 383px;*/
  background: #ffffff;
  height: 100%;
  border-radius: 8px;
  padding: 30px 70px;
  box-sizing: border-box;
  margin-top: 30px;
}
.AccessApplicationDetails__title {
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.01em;
  color: #4d4d4f;
  margin: 0 0 10px 0;
}
.AccessApplicationDetails__head {
  display: flex;
}
.AccessApplicationDetails__head--sides {
  margin-top: 30px;
  margin-right: 88px;
  min-width: 230px;
}
.AccessApplicationDetails__text--title {
  margin-top: 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #4d525b;
  margin-bottom: 7px;
}
.AccessApplicationDetails__text--title--high {
  margin-top: 20px;
}
.AccessApplicationDetails__text {
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #4d525b;
  padding: 0;
  margin: 0 0 20px 0;
}
.AccessApplicationDetails__text--block {
  position: relative;
}
.AccessApplicationDetails__textarea {
  width: 100%;
  height: 160px;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  font-family: 'Source Sans Pro';
  border-radius: 4px;
  outline: none;
  margin-bottom: 20px;
  resize: none;
  margin-top: 40px;
  font-size: 18px;
}
.AccessApplicationDetails__textarea:focus {
  border: 1px solid #e34a4e;
}
.AccessApplicationDetails__textarea:focus::-webkit-input-placeholder {
  color: #e34a4e;
}
.AccessApplicationDetails__textarea:focus::-moz-placeholder {
  color: #e34a4e;
}
.AccessApplicationDetails__textarea:focus:-ms-input-placeholder {
  color: #e34a4e;
}
.AccessApplicationDetails__textarea:focus:-moz-placeholder {
  color: #e34a4e;
}
.AccessApplicationDetails__btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.AccessApplicationDetails__textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c0c0c0;
  font-size: 18px;
  line-height: 20px;
  font-family: 'Source Sans Pro';
}
.AccessApplicationDetails__textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #c0c0c0;
  font-size: 18px;
  line-height: 20px;
  font-family: 'Source Sans Pro';
}
.AccessApplicationDetails__textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #c0c0c0;
  font-size: 18px;
  line-height: 20px;
  font-family: 'Source Sans Pro';
}
.AccessApplicationDetails__textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #c0c0c0;
  font-size: 18px;
  line-height: 20px;
  font-family: 'Source Sans Pro';
}

.AccessApplicationDetails__message--button {
  color: #c0c0c0;
  font-size: 12px;
  cursor: pointer;
  display: block;
  width: 100%;
  text-align: right;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.3), white 80%);
  position: absolute;
  right: 0;
  bottom: 0;
}

.AccessApplicationDetails__content__text {
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: #4d525b;
  padding: 0;
  margin: 5px 0 0 0;
}
.AccessApplicationDetails__content__text--commentDate {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;

  color: #4d525b;
}
.messageClassToggleHidden {
  height: 125px;
  position: relative;
  overflow: hidden;
}
.messageClassToggleHidden--empty {
  min-height: 0;
}
.messageClassToggleOpen {
  position: relative;
}
.messageClassToggleOpen > .AccessApplicationDetails__message--button {
  background: none;
  position: relative;
}
@media (max-width: 425px) {
  .AccessApplicationDetails {
    padding: 0;
  }
  .AccessApplicationDetails__title {
    display: none;
  }
  .AccessApplicationDetails__head {
    flex-direction: column;
  }
  .AccessApplicationDetails__text--title,
  .AccessApplicationDetails__text {
    margin-left: 30px;
  }
  .AccessApplicationDetails__textarea {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
  }
  .AccessApplicationDetails__btn {
    justify-content: center;
  }
}
