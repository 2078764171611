.NoResults {
    width: 100%;
    height: 717px;
}
.NoResults__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.NoResults__ring {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    border: 9px solid #BDBDBD;
    margin-top: 112px;
}
.NoResults__title {
    font-weight: bold;
    font-size: 60px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #BDBDBD;
    margin: 20px 0 0 0;
}
.NoResults-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #828282;

}