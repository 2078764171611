.PersonalProfile {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    background-color: #FFFFFF;
    padding-left: 45px;
    padding-right: 45px;
}

.footer {
    display: flex;
    flex-direction: row;
}