.MainPage-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    min-height: calc(100vh - 70px);
    justify-content: space-between;
    
    
}
.MainPage-body_main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 1 1 auto;
}
.MainPage-body > * {
    margin-bottom: 20px;
}
.MainPage-body > *:first-child {
    margin-top: 20px;
}

.MainPage-body-header {
    background: white;
    margin-top: 10px;
    border-radius: 8px;
    width: 394px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}
.MainPage-body-content {
    margin-top: 20px;
    height: 80px;
}

@media (max-width: 1440px) {
    .MainPage-body-content {
        margin-top: 20px;
    }

    .MainPage-body > *:first-child {
        margin-top: 10px;
    }
}
.MainPage-body-content h1 {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */
    text-align: center;
    letter-spacing: 0.01em;
    color: #828282;
}

/* info modal */

.ModalWindow__Trial__Click_Background {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
}

.ModalWindow__Trial_Info {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99;
    width: 750px;
    height: 450px;
    background-color: #fff;
    border-radius: 15px;
}

.ModalWindow__Info_Item {
    margin: 25px 20%;
}
.continue_buttons{
    padding: 0 30px;
    display: flex;
    width: 80%;
    justify-content: space-between;
}

.ModalWindow__Info_Item__Text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
}

.Bold__Text {
    font-size: 20px;
    font-weight: 600;
}

.ModalWindow__Info_Item__Btn {
    cursor: pointer;
    border: 1px solid #E34A4E;
    border-radius: 4px;
    height: 45px;
    width: 200px;
    color: #e34a4e;
    background-color: transparent;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}
.new_request{
    background-color: #E34A4E;
    color: white;
}
.trial_info{
    margin: 0;
}
.MainPage__row{
    display: flex;
    gap: 20px;
}
.MainPage_company{
    position: relative;
    margin-bottom: 10px;
    min-width: 300px;
}
.footer{
    margin: 10px 0 0 0;
    padding: 20px 0;
}
@keyframes openModal {
    from {
        opacity: 0;
        backdrop-filter: blur(0);
    }
    to {
        opacity: 1;
        backdrop-filter: blur(2px);
    }
}

@media (max-width: 1024px) {
    .ModalWindow__Trial_Info {
        width: 500px;
        height: 300px;
    }
}
@media (max-width: 850px) {
    .MainPage__row{
        flex-direction: column;
    }
}

