.DropDownText{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    
}
.DropDownText__open_icon{
    margin: 0 15px;
    transition: 0.4s;
}
.DropDownText__title{
    display: flex;
    align-items: center;
    padding: 5px;
    min-height: 60px;
    border-radius: 8px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 23px;
    letter-spacing: 0.01em;
    cursor: pointer;
    color: #4D525B;

}
.DropDownText__text{
    padding: 20px 25px;

}
.DropDownText__feedback{
    padding: 20px 25px;
}
.faq_question-btn{
    cursor: pointer;
    margin: 0 5px;
    background: none;
    border: none;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #4FC6E0;
}

.DropDownText__list > * {
    margin: 10px 0;
}

.rotate_45{
    transform: rotate(-45deg);
}