.ModalRegions__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.ModalRegions {
    width: 245px;
    background: #4FC6E0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 8px 8px 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 45px;
    left: 50px;
    z-index: 99;
}
.ModalRegions__content {
    background: #F5F5F5;
    border-radius: 0 0 8px 8px;
}
.ModalRegions__header {
    width: 205px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ModalRegions__header--text {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}
.ModalRegions__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
}
.ModalRegions--search {
    display: flex;
    margin-left: 20px;
    width: 205px;
    height: 40px;
    margin-top: 10px;
    padding-left: 15px;
    border:1px solid #C0C0C0;
    border-radius: 4px;
}
.ModalRegions--search::-webkit-search-cancel-button {
    display: none;
}

.ModalRegions__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 2px 0;
    border: 1px solid #C0C0C0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    background: #fff;
    padding-left: 10px;
}

.ModalRegions__input{
    margin-top: 15px;
    display: none;
}
.ModalRegions__department {
    display: block;
    width: 158px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #333333;
    margin-left: 10px;
}
.ModalRegions__label--icon::before {
    content: '';
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../../assets/icons/ic-remember-frame.svg') center / cover no-repeat;
    margin-right: 7px;
}
.ModalRegions__input:checked ~ .ModalRegions__label--icon::before {
    background:url('../../../assets/icons/ic-check-black.svg')center / cover no-repeat,
    url('../../../assets/icons/ic-remember-frame.svg') center / cover no-repeat;
}
