.RedButton {
    width: 100%;
    height: 44px;
    margin: 10px auto;
    background: white;
    cursor: pointer;
    color: #E34A4E;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid #E34A4E;
    border-radius: 4px;
    outline: none;
}

.RedButton:hover {
    color: white;
    border-color: #E34A4E;
    background: #E34A4E;
}
.RedButton:active {
    transform: scale(0.99);
}
.RedButton:disabled {
    background-color: #E0E0E0;
    color: #989898;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.16px;
    border-color: #989898;
    cursor:not-allowed;
}
.RedButton:disabled:hover{
    background: #E0E0E0;
    color: #989898;
    border-color: #989898;
}