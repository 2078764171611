

.ModalBase_content {
    position: relative;
    background: #fff;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 20px;
    z-index: 10;
    max-height: 90vh;
    overflow-y: auto;
    animation: modalContentAnim 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.modal_wrapper {
    backdrop-filter: blur(4px);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.modal_bg {
    width: 100%;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background: #fff;
}
.modal {
    position: relative;
    min-width: 100px;
    min-height: 100px;
    padding: 40px;
    border-radius: 16px;
}
.modal__title{
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 10px;
}