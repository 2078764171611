.FormBlock_title{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.01em;

    color:#4D525B;
    margin: 20px 0;
}