.TurquoiseButton {
    width: 205px;
    height: 40px;
    background: #4FC6E0;
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0;
    cursor: pointer;
    user-select: none;
}