.trials_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.trials-text_block {
    margin-bottom: 15px;
}

.trials-text {
    color: black;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Source Sans Pro';
    text-align: center;
}

.read-more_btn {
    cursor: pointer;
    width: 90%;
    margin: 0 5%;
    height: 45px;
    font-family: 'Source Sans Pro';
    color: #E34A4E;
    font-weight: 700;
    font-size: 16px;
    background-color: #ffffff;
    border: 1px solid #E34A4E;
    border-radius: 4px;
    min-width: max-content;
    padding: 3px 20px;
}