.DirectorsCabinet__Statistic__Container {
  font-family: 'Source Sans Pro';
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: stretch;
  margin-top: 30px;
  gap: 16px;
}
.BI__error{
  margin: 0 auto;
  text-align: center;
}
.BI_ERROR{
  font-size: 70px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  
  border-radius: 8px;
  background: url("../../../assets/images/blur_BI.png");
  background-size: 100%;
  min-height: 650px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.01em;

  /* Dark Gray */

  color: #4B4C4D;
}
.BI_ERROR__title{
  width: 300px;
}
.BI_ERROR__text{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.01em;
  
  /* Dark Gray */
  
  color: #4B4C4D;

}
.recharts-surface{
  overflow: visible;
}
.DirectorsCabinet__StatisticForPeriod {
  background-color: #fff;
  grid-column: 1/3;
  grid-row: 2/3;
  border-radius: 8px;
  position: relative;
}

.StatisticForPeriod__TextInformation {
  display: flex;
  justify-content: space-evenly;
  color: #4d525b;
  margin-top: 18px;
}

.StatisticForPeriod__Header {
  font-weight: 700;
  font-size: 12px;
}

.StatisticForPeriod__RequestsAverage {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.StatisticForPeriod_RequestsMaxMin {
  font-weight: 400;
  font-size: 12px;
  color: #828282;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.RequestsAverage__Header {
  font-weight: 700;
  font-size: 30px;
}

.RequestsAverage__LabelPercent {
  display: flex;
  align-items: center;
}

.RequestsAverage__Label {
  font-weight: 400;
  font-size: 12px;
  color: #828282;
}

.RequestsAverage__Percent {
  font-weight: 700;
  font-size: 14px;
}

.StatisticForPeriod__Graphic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

.StatisticForPeriod__Graphic__Months {
  width: 90%;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 8px;
  position: absolute;
  top: 200px;
}

.LinechartLabel {
  font-weight: 400;
  font-size: 8px;
  fill: #828282;
}

.recharts-cartesian-grid-horizontal line {
  stroke: #c4c4c4;
}

.recharts-cartesian-grid-horizontal line:last-child {
  stroke: #fff;
}
.recharts-cartesian-grid-horizontal line:nth-last-child(2) {
  stroke: #fff;
}

.recharts-cartesian-grid-vertical line {
  stroke: #f3f6f9;
}
.recharts-cartesian-grid-vertical line:last-child {
  stroke: #fff;
}
.recharts-cartesian-grid-vertical line:first-child {
  stroke: #fff;
}

.DirectorsCabinet__DepartmentRequests {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  grid-row: 2/3;
  padding-left: 26px;
}

.DepartmentRequests__Header {
  font-weight: 700;
  font-size: 12px;
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left: 2px;
}

.Request__Container {
  font-weight: 600;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
  padding-bottom: 9px;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 2px;
}

.Request__Value {
  margin-right: 15px;
}

.Last__Request__Container {
  border-bottom: none;
}

.DirectorsCabinet__JobReviews {
  background-color: #fff;
  border-radius: 8px;
  grid-row: 2/3;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 21px;
}

.JobReviewsTotal__Container {
  display: grid;
  grid-template-columns: 1fr 0.9fr;
  font-weight: 700;
}

.JobReviewsTotal__Value {
  font-size: 50px;
}

.JobReviewsTotal__Label {
  font-size: 12px;
  color: #828282;
  display: flex;
  align-items: center;
}

.JobReviewsTotal__Percent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.JobReviewsDiagrams__Container {
  display: flex;
}

.JobReviewsDiagrams__Pie {
  display: flex;
  justify-content: center;
  align-items: center;
}

.JobReviewsDiagrams__LikesDislikes {
  display: flex;
  position: relative;
}

.JobReviewsDiagrams__ReviewsAmount__Container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-items: end;
  position: absolute;
  right: 23px;
}

.JobReviewsDiagrams__ReviewsIcons__Container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: end;
  margin-left: 9px;
}

.LikesDislikes {
  width: 25px;
  height: 25px;
  padding-bottom: 5px;
}

.LikesDislikes__Amount {
  font-weight: 400;
  font-size: 12px;
  color: #828282;
}

.Dislikes__Amount {
  align-self: end;
  position: absolute;
  top: 62px;
}

.JobReviews__BadGood__Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  font-size: 10px;
  color: #828282;
  gap: 3px;
  margin-left: 14px;
}

.JobReviews__BadGood__GreyLine {
  initial-value: '';
  border-bottom: 1px solid #c4c4c4;
}


.DirectorsCabinet__UserWithMostRequests {
  grid-column: 1/3;
  background-color: #fff;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 0.9fr;
  padding-left: 28px;
  padding-right: 12px;
}

.UserWithMostRequests__UserInformation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 11px;
  padding-bottom: 24px;
}

.UserWithMostRequests__Label {
  font-weight: 400;
  font-size: 12px;
}

.UserWithMostRequests__Name {
  font-weight: 700;
  font-size: 20px;
}

.UserWithMostRequests__NumberID {
  display: flex;
  gap: 19px;
  align-items: center;
}

.UserWithMostRequests__ID {
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.UserWithMostRequests__Number {
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.UserWithMostRequests__RequestsInformation {
  display: flex;
  gap: 10px;
}

.RequestsInformation__Number {
  font-weight: 700;
  font-size: 70px;
  display: flex;
  align-items: center;
}

.RequestsInformation__Label {
  font-weight: 400;
  font-size: 12px;
  color: #828282;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DirectorsCabinet__UserTariff {
  display: flex;
  flex-direction: column;
  grid-column: 3/5;
}

.UserTariff__Label {
  font-weight: 700;
  font-size: 45px;
  color: #bdbdbd;
}

.UserTariff__Price {
  font-weight: 400;
  font-size: 30px;
  color: #bdbdbd;
}
.MVP__Specialists{
  width: 50%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin: 30px 0;
}
.MVP__Specialist_title{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  
  color: #828282;
}
.MVP__Specialist_row{
    margin: 5px 0;
}
.MVP__Specialist_row_title{
  width: 50%;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;

  color: #4D525B;

}
.MVP__Specialist_row{
  display: flex;

}
.MVP__Specialist_row_id{
  width: 25%;
  padding: 0 10px;
  text-align: center;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.01em;

  color: #4D525B;
  display: flex;
  align-items: flex-end;
  gap: 4px;
}
.MVP__Specialist_row_count{
  width: 25%;
  padding: 0 10px;
  text-align: center;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  
  color: #4D525B;
}
.MVP__Specialist_row ._header{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.01em;
  
  color: #828282;
}
.MVP__Specialist_id_lastNubmers{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;

  color: #4D525B;
}
@media (max-width: 1370px) {
  .DirectorsCabinet__Statistic__Container {
    grid-template-columns: repeat(4, 1fr);
    justify-items: stretch;
    margin-top: 30px;
    gap: 23px;
  }
  .StatisticBlock__Label {
    font-size: 9px;
  }
  .UserTariff__Label {
    font-size: 40px;
  }
}
@media (max-width: 1270px) {
  .UserWithMostRequests__Name {
    font-size: 14px;
  }

  .UserWithMostRequests__ID {
    font-size: 12px;
  }

  .UserWithMostRequests__Number {
    font-size: 12px;
  }

  .DirectorsCabinet__UserTariff {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 1170px) {
  .StatisticBlock__Value {
    display: flex;
    align-items: center;
    font-size: 55px;
  }

  .DirectorsCabinet__LongestRequest div {
    font-size: 11px;
  }
  .LongestRequest__Comment {
    font-size: 11px;
  }

  .LongestRequest__Worker__ID {
    font-size: 11px;
  }

  .LongestRequest__User__ID {
    font-size: 11px;
  }

  .StatisticForPeriod_RequestsMaxMin p {
    font-size: 10px;
  }
  .StatisticForPeriod__Header {
    font-size: 10px;
  }
  .RequestsAverage__Header {
    font-size: 20px;
  }

  .UserWithMostRequests__ID {
    font-size: 10px;
  }

  .UserWithMostRequests__Number {
    font-size: 10px;
  }

  .DirectorsCabinet__UserTariff p {
    font-size: 30px;
  }

  .DepartmentRequests__Requests div {
    font-size: 12px;
  }

  .Request__Container span {
    font-size: 11px;
  }
}

@media (max-width: 900px) {
  .DirectorsCabinet__UserWithMostRequests {
    padding-left: 5px;
  }

  .UserWithMostRequests__Label {
    font-size: 10px;
  }

  .UserWithMostRequests__Name {
    font-size: 10px;
  }

  .UserWithMostRequests__ID {
    font-size: 9px;
  }

  .UserWithMostRequests__Number {
    font-size: 9px;
  }

  .Request__Container span {
    font-size: 9px;
  }
}
