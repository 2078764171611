.PPHeaderForm .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.title .personalInfo {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    /*line-height: 45px;*/
    letter-spacing: 0.01em;
    margin-bottom: 10px;
    color: #4D525B;
}

.title .summation {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    /*line-height: 20px;*/
    letter-spacing: 0.01em;
    color: #4D525B;
}

.link {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 25px;
}
.link .linkInput-content {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #4D525B;
    margin-bottom: 7px;
}
.link .linkInput {
    display: flex;
    flex-direction: row;
}

.link .linkInput-input {
    background: #FFFFFF;
    width: 100%;
    height: 45px;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
    border-radius: 4px 0 0 4px;
    padding-left: 60px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-size: 16px;
    background: url("/src/assets/icons/link-icon.svg")20px no-repeat;
}

.linkInput-input::placeholder {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #C0C0C0;
}

.link .copyLinkBtn {
    width: 40%;
    height: 45px;
    background: #E34A4E;
    border-radius: 0 4px 4px 0;
    border: none;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}
.link .copyLinkBtn:hover {
    cursor: pointer;
    background: #c42e32;
}