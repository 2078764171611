.FAQ-container_inner {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: 65%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #4FC6E0;
    animation: faq-block-hide ease-in-out .25s;
    z-index: 99;
}

.FAQ-left_block {
    width: 150px;
    height: 100%;
    background-color: #00ACCD;
}

.faq-logo {
    cursor: pointer;
    position: fixed;
    top: 20px;
    left: 12px;
}

.faq-icon {
    color: #C53036;
}

.faq-icon, .faq {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.01em;
}

.FAQ-container {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 98;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
}

.show {
    animation: faq-block-show ease-in-out .25s;
}

@keyframes faq-block-show {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes faq-block-hide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}