.FileBlock{
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    border-radius: 4px;
    padding: 5px 35px 5px 8px;
    position: relative;
}
.FileBlock__delete_button{
    background-image: url("../../../../../assets/icons/X.svg");
    background-color: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;

}
.FileBlock__name{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;

    color: #4D525B;
}
.FileBlock__size{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #4D525B;
}
.FileBlock__removing{
    animation: removing 1s;
}
@keyframes removing {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.1);
    }
    100%{
        transform: scale(0);
    }
}