.ButtonLogin {
    height: 44px;
    border: 1px solid #E34A4E;
    box-sizing: border-box;
    border-radius: 4px;
    color:#E34A4E;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    background: white;
    cursor: pointer;
    outline: none;
    transition: 0.2s;
}
.ButtonLogin:hover {
    background: #E34A4E;
    color: white;
}
.ButtonLogin:active {
    transform: scale(0.99);
}
