.FunctionBlock{
    margin: 27px 0;
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 27px 20px;
}

    .FunctionBlock_title{
        margin: 0 0 17px 0;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #4D525B;
}