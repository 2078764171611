.MiniTable {
    padding: 12px;
    background: white;
    margin: 20px 0;
    border-radius: 8px;
    width: 100%;
    transition: min-height 1s ease;
}
.MiniTable__title {
    padding: 6px 0;
    border-bottom: 1px solid #c4c4c4;

    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #4d4d4f;
}
.MiniTable__row {
    display: flex;
}
.MiniTable__row_subtitles {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.01em;
    color: #828282;
}
.MiniTable__block.block_name {
    width: 25%;
}
.MiniTable__block.statistic_data {
    width: 15%;
}
.MiniTable__data {
    margin: 5px 0;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;

    color: #4d525b;
}
.MiniTable__showFullTable {
    position: relative;
    display: flex;
    justify-content: end;
    align-self: flex-end;
    cursor: pointer;
    color: #828282;
}

.MiniTable__for_open {
    height: 100;
    transition: 0.5s;
    width: 95%;
}
.MiniTable__list {
    position: relative;
}

.MiniTable__list_content {
    position: relative;
    opacity: 1;
    transition: 0.4s;
}
