.StatisticBlock__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  justify-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 0 10px;
}

.StatisticBlock__Value {
  font-weight: 700;
  font-size: 70px;
}

.StatisticBlock__Labels__Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.StatisticBlock__Label {
  font-weight: 400;
  font-size: 12px;
  color: #828282;
}

.StatisticBlock__Percent {
  font-weight: 700;
  font-size: 14px;
}

.StatisticBlock__Percent__Positive {
  color: #3cc13b;
}
.StatisticBlock__Percent__Negative {
  color: #c53036;
}
