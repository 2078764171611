.VksInviteModule{
    width: 100%;
    max-width: 517px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
    gap: 10px;
    
}
.VksInviteModule__form{
    width: 100%;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.VksInviteModule__form .FormInput_group{
    margin: 0;
}
.VksInviteModule__form_hint{
    color: #4D525B;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;

}
.VksInviteModule__form_title{
    color: #4D525B;
    font-family: "Source Sans Pro";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
}
.VksInviteModule__form_buttons{
    display: flex;
    justify-content: space-between;
}

.VksInviteModule__blue_button{
    border-radius: 4px;
    background: #4FC6E0;
    color: white;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.16px;
    padding: 12px;
    border: 0;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all .3s ease;
}
.VksInviteModule__blue_button:hover, .VksInviteModule__blue_button:focus-visible{
    background: #37d1f3;    
}
.VksInviteModule__blue_button:active{
    background: #00ABCD;
}
.VksInviteModule__form_submit{
    border-radius: 4px;
    background: #E34A4E;
    color: white;
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.16px;
    padding: 12px;
    border: 0;
    cursor: pointer;
    transition: all .3s ease;
}

.VksInviteModule__form_submit:hover, .VksInviteModule__form_submit:focus-visible{
    background: #C53036;
}
.VksInviteModule__form_submit:active{
    background: #9D061C;
}
.VksInviteModule__form_submit:disabled{
    opacity: .6;cursor: default;
}
.notification {
    display: block;
    background-color: #4caf50;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    position: absolute;
    z-index: 9999;
    opacity: 1;
    transition: opacity .5s ease;
    bottom: 105%;
    left: 105%;
    width: max-content;
}
.notif_hidden {
    opacity: 0;
    
    transition: opacity .5s ease;
}
.VksInviteModule__error_title{
    color: #4D525B;
    text-align: center;
    font-family: Source Sans Pro;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
}
.VksInviteModule__error_text{
    text-align: center;
    color: #4D525B;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
}
.VksInviteModule__form_error{
    display: flex;
    flex-direction: column;
    align-items: center;
}