.OpenAccessForm{
    padding: 35px 25px;
    background: #fff;
    border-radius: 8px;
    
}

.goBack{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    letter-spacing: 0.01em;
    color: #4FC6E0;
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

.OpenAccessForm_title{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    letter-spacing: 0.01em;
    color: #4D4D4F;
    margin: 40px 50px;
}

