
.faq-header-title-text, .faq-header-info-text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    color: #FFFFFF;
}

.faq-header-title-text {
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    letter-spacing: 0.01em;
}

.faq-header-info-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
}