.DirectorsCabinet__LongestRequest {
    background-color: #fff;
    border-radius: 8px;
    grid-column: 1/5;
    display: flex;
    flex-direction: column;
    padding-top: 2px;
    padding-left: 12px;
    padding-bottom: 10px;
    padding-right: 21px;
    cursor: pointer;
    position: relative;
  }
  .DirectorsCabinet__LongestRequest_preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #333333;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    opacity: 0;
    transition: all .5s ease;
    text-decoration-line: underline;
  }
  .DirectorsCabinet__LongestRequest_preview:hover {
    opacity: 1;
  }
  
  .DirectorsCabinet__LongestRequest::before{
    content: "";
    opacity: 0;
    transition: all .5s ease;
    color: white;
    font-size: 15px;
    position: absolute;
    top: 0;
    left: 0;
    
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
  }
  .DirectorsCabinet__LongestRequest:hover::after{

    opacity: 1;
  }
  .DirectorsCabinet__LongestRequest:hover::before{

    opacity: 1;
  }
  
  .LongestRequest__Label {
    font-weight: 700;
    font-size: 12px;
    border-bottom: 1px solid #c4c4c4;
    margin-top: 7px;
    margin-bottom: 17px;
    padding-bottom: 2px;
  }
  
  .LongestRequest__Information__Container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
  }
  .LongestRequest__Information__Container > * {
    max-width: 16.6%;
  }
  
  .LongestRequest__Date__Container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
  
  .LongestRequest__Date {
    margin-bottom: 5px;
  }
  
  .LongestRequest__Status {
    font-weight: 600;
    font-size: 14px;
    background: #3cc13b;
    color: white;
    border-radius: 11px;
    width: 109px;
    display: flex;
    justify-content: center;
  }
  
  .LongestRequest__User__Container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    max-width: 16.6%;
  }
  
  .LongestRequest__User__ID {
    font-weight: 700;
    font-size: 14px;
  }
  
  .LongestRequest__ServiceType {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .LongestRequest__Sells {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .LongestRequest__Worker__Container {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  
  .LongestRequest__Worker__ID {
    font-weight: 700;
    font-size: 14px;
  }
  .LongestRequest__Comment {
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-weight: 400;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .LongestRequest__Comment__block{
    overflow: hidden;
    width: 300px;
    max-height: 30px;
  }
  .LongestRequest__Feedback {
    display: flex;
    height: 100%;
    gap: 6px;
    align-items: flex-start;
  }
  
  .LongestRequest__Dislike {
    width: 24px;
    height: 24px;
  }
