.ModalWorker__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.ModalWorker {
    width: 378px;
    height: 44px;
    border: 1px solid #4FC6E0;
    background: white;
    border-radius: 0px 8px 0px 0px;
    z-index: 99;
    top: 45px;
    position: absolute;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.ModalWorker__input {
    width: 340px;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: #4D525B;
    outline: none;
    border: none;
    margin-left: 13px;
}
.ModalWorker__input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 23px;
  }
.ModalWorker__input::-moz-placeholder { /* Firefox 19+ */
    font-family: "Source Sans Pro";
    color:  #C0C0C0;
    font-size: 18px;
    line-height: 23px;
  }
.ModalWorker__input:-ms-input-placeholder { /* IE 10+ */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 23px;
  }
.ModalWorker__input:-moz-placeholder { /* Firefox 18- */
    font-family: "Source Sans Pro";
    color: #C0C0C0;
    font-size: 18px;
    line-height: 23px;
  }
.ModalWorker__arrowBtn {
    cursor: pointer;
    width: 12px;
    height: 10px;
    background: url('../../../assets/icons/ic-wrap-worker-search.svg')center / cover no-repeat;
}
.ModalWorker__resultsItem {
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #4D525B;
    margin: 0;
    padding: 7px 13px;
    display: block;
    cursor: pointer;
    transition: 0.1s;
}
.ModalWorker__resultsItem:hover {
    background: #00ACCD;
}
.ModalWorker__results {
    background: #F5F5F5;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 8px 8px;
    width: 378px;
    height: 378px;
    overflow-y: scroll;
    min-height: 171px;
    position: absolute;
    top: 45px;
    box-sizing: border-box;
    padding: 13px 0;
}
.ModalWorker__results::-webkit-scrollbar-track
{
    border: 5px solid white;
    background-color: #b2bec3;
}

.ModalWorker__results::-webkit-scrollbar
{
    width: 8px;
    background-color: #dfe6e9;
}

.ModalWorker__results::-webkit-scrollbar-thumb
{
    background-color: #4FC6E0;
    border-radius: 20px;
}