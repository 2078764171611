.HrModalWindow{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.HrModalWindow__header{
    width: 100%;
    height: 60px;
    background-color: #fff;
    background-image: url('../../../assets/logos/asista_header_bg.svg');
    background-size: 640px 58px;
    background-position: left;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
}
.HrModalWindow__header_logo{
    margin-top: 14px;
}

.HrModalWindow__title{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #4D525B;
}

.HrModalWindow__phone{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #C0C0C0;

}

.HrModalWindow__phone_description{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #BDBDBD;
}
.HrModalWindow__functions{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}