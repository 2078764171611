.VksReadyModal__copy{
    cursor: pointer;
    box-sizing: border-box;
    transition: all .4s ease;
    border-radius: 2px;
    position: relative;
    max-width: 100%;
}
.VksReadyModal__copy_active{
    background: #c2c2c2;
}
.VksReadyModal__notification {
    display: block;
    background-color: #4caf50;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    position: absolute;
    z-index: 9999;
    opacity: 1;
    transition: opacity .5s ease;
    bottom: 105%;
    left: 45%;
    width: max-content;
    display: block;
}
.VksReadyModal__notif_hidden {
    opacity: 0;
    display: none;
    transition: opacity .5s ease;
}
  
.Modal__buttons{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}