.horizontal_radio{
    display: flex;
    flex-wrap: wrap;
}

.horizontal_radio> *{
    margin-right: 100px !important;
}

.horizontal__checkboxes{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.ResultBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.horizontal__flex{
    display: flex;
    align-items: center;
}

.flex_wrap{
    display: flex;
    flex-wrap: wrap;
}