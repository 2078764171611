.BIRegions__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.BIRegions {
    width: 245px;
    background: #4fc6e0;
    box-shadow: 0 3px 3px rgb(0 0 0 / 25%);
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 35px;
    left: -19px;
    z-index: 99;
}
.BIRegions__content {
    background: #F5F5F5;
    border-radius: 0 0 8px 8px;
    overflow-y: scroll;
    height: 300px;
}
.BIRegions__header {
    width: 205px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.BIRegions__header--text {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}
.BIRegions__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
}
.BIRegions--search {
    display: flex;
    width: 205px;
    height: 40px;
    margin: 10px auto;
    padding-left: 15px;
    border:1px solid #C0C0C0;
    border-radius: 4px;
}
.BIRegions--search::-webkit-search-cancel-button {
    display: none;
}

.BIRegions__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 2px 0;
    border: 1px solid #C0C0C0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
    background: #fff;
    padding-left: 10px;
}

.BIRegions__input{
    margin-top: 15px;
    display: none;
}
.BIRegions__department {
    display: block;
    width: 158px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #333333;
    margin-left: 10px;
}
.BIRegions__label--icon::before {
    content: '';
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../../../assets/icons/ic-remember-frame.svg') center / cover no-repeat;
    margin-right: 7px;
}
.BIRegions__input:checked ~ .BIRegions__label--icon::before {
    background:url('../../../../assets/icons/ic-check-black.svg')center / cover no-repeat,
    url('../../../../assets/icons/ic-remember-frame.svg') center / cover no-repeat;
}
.BIRegions__content::-webkit-scrollbar-track
{
    border: 5px solid white;
    background-color: #b2bec3;
}

.BIRegions__content::-webkit-scrollbar
{
    width: 8px;
    background-color: #dfe6e9;
}

.BIRegions__content::-webkit-scrollbar-thumb
{
    background-color: #4FC6E0;
    border-radius: 20px;
}