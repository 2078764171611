.FAQ-body {
    /*display: grid;*/
    /*grid-template-columns: 1fr 1fr;*/
    /*grid-template-areas: ".faq-item" ".faq-item"*/
    /*                ".faq-item-long" ".faq-item-long"*/
    /*                ".faq-item-long" ".faq-item-long";*/

    display: flex;
    margin-top: 25px;
    flex-direction: row;
    flex-wrap: wrap;
}

.faq-hidden-answer {
    display: none;
    flex-direction: column;
    margin: 0 10% 0 13%;
}
.faq_question{
    margin: 10px 10px 20px 10px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #4D525B;

}
.faq_question-text{
    margin-right: 20px;
}

.faq_question-btn{
    cursor: pointer;
    margin: 0 5px;
    background: none;
    border: none;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #4FC6E0;
}
.faq-item_long > .faq-item > .faq-hidden-answer {
    margin: 0 10% 0 3%;
}

.faq-item {
    cursor: pointer;
    width: 47.5%;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 25px;
}

.active-item {
    max-height: unset;
    transition: max-height linear .25s;
}

.not-active-item {
    max-height: 60px;
    transition: max-height linear .25s;
}

.icons-title_block {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
}

.block-covering {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.faq-item:first-child, .faq-item:nth-child(3) {
    margin-right: 2.5%;
}

.faq-item:nth-child(2), .faq-item:nth-child(4) {
    width: 50%;
}

.faq-item_long {
    width: 100%;
}

.faq-item_long > .faq-item {
    width: 100%;
}

.close-add-icon {
    margin: 0 15px;
    width: 30px;
    height: 30px;
    background: url("/src/assets/icons/open-icon.svg") no-repeat center;
    background-size: 30px;
}

.faq-item_title {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.01em;

    color: #4D525B;
}

.list-advises > * {
    margin-bottom: 10px;
}

@media (max-width: 1024px) {
    .faq-item_title {
        font-size: 16px;
    }
}
@media (max-width: 1280px) {
    .faq-item_title {
        line-height: inherit;
        font-size: 16px;
    }
}