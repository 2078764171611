* {
    box-sizing: border-box;
}

body {
    background-color: #EDEDED;
}

.TrialForm__Top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 35%;
}

.TrialForm__Name_Info {
    background-color: #F3F6F9;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.TrialForm__Name_Info_Text {
    font-family: 'Source Sans Pro';
    font-size: 26px;
    line-height: 45px;
    color: #4B4C4D;
}

.TrialForm__Body {
    padding: 25px 5.5% 0 8%;
    display: flex;
}

.TrialForm__Body__Center {
    width: calc(65% - 30px);
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}

.TrialForm__Bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5.5%;
}

.TrialForm__Center {
    padding: 40px 70px 0 70px;
    background-color: #F3F6F9;
    border-radius: 8px;
}

.TrialForm__Topic_Block {
    margin: 25px 0 15px 0;
}

.TrialForm__Body_Block {
    padding-bottom: 30px;
}

.TrialForm__Body_Topic_Block {
    margin: 40px 0 15px 0;
}

.TrialForm__Topic_Text_Block {
    margin-top: 25px;
}

.TrialForm__Topic_Text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #4D525B;
}

.TrialForm__Problem_Input, .TrialForm__Textarea {
    width: 100%;
    color: #000;
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-size: 16px;
    border: none;
    border-radius: 4px;
}

.TrialForm__Problem_Input::placeholder, .TrialForm__Textarea::placeholder {
    font-family: 'Source Sans Pro';
    color: #BDBDBD;
    font-weight: 400;
    font-size: 16px;
}

.TrialForm__Problem_Input {
    height: 40px;
    padding-left: 25px;
}

.TrialForm__Textarea__Box {
    margin-top: 15px;
}

.TrialForm__Textarea {
    resize: none;
    padding: 20px 30px 0 25px;
    font-size: 18px;
    height: 130px;
    letter-spacing: 0.01em;
}

.TrialForm__File {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
    position: relative;
}

.TrialForm__Clip_File {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 160px;
}

.TrialForm__File__ChooseText {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-size: 16px;
    color: #4B4C4D;
    margin: 0 15px;
}

.TrialForm__MoreInfo {
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #4B4C4D;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #4B4C4D;
}

.TrialForm__PickDate_Block {
    display: flex;
    flex-direction: row;
}

.TrialForm__Select {
    position: absolute;
    width: 140px;
    height: 35px;
    opacity: 0;
    left: 0;
}

.TrialForm__Timezone_Block {
    position: relative;
    width: 140px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    border-radius: 4px;
    background: #fff url("../../../assets/icons/ic-arrow-select-black.svg") no-repeat 85%;
}

.TrialForm__Timezone_Block:nth-child(2) {
    margin: 0 20px;
}

.TrialForm__Timezone_Text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #C0C0C0;
}

.TrialForm__Submit_Button {
    cursor: pointer;
    background-color: #A7E3EF;
    color: #fff;
    border: none;
    height: 40px;
    width: 250px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    border-radius: 4px;
}

.TrialForm__Personal_Data {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 150px;
}

.TrialForm__Personal_Checkbox {
    width: 20px;
    height: 20px;
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: pointer;
    /*appearance: none;*/
    /*-webkit-appearance: none;*/
    /*-moz-appearance: none;*/
}

.TrialForm__Personal_Data_Text_Block {
    margin-left: 7px;
}

.TrialForm__Personal_Data_Text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4B4C4D;
}

.TrialForm__Footer_Block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #F3F6F9;
    padding: 10px 0;
}

.TrialForm__Footer_Block {
    margin-top: 50px;
}

.TrialForm__Footer_Title_Text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #4D525B;
}

.TrialForm__Footer_SocialMedias {
    margin-left: 12px;
}

.TrialForm__Footer_SocialMedias > * {
    cursor: pointer;
    margin: 0 4px;
}