

.Hoc_loader {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    z-index: 100;
    background: url(./loader.gif) center center no-repeat;
}
.Hoc_loaderBG {
    position: fixed;
    z-index: 101;
    width: 100%;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(20,20,20, 0.5);
}
