.Container{
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

@media(max-width: 1100px){
    .Container{
        padding:0 20px;
    }
}
@media (max-width: 1370px) {
    .Container {
        max-width: 1100px;
    }
}

@media (max-width: 1270px) {
    .Container {
        max-width: 1000px;
    }
}
@media (max-width: 1170px) {
    .Container {
        max-width: 900px;
    }
}