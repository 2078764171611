.ResultsItemWorker__comment {
    cursor: pointer;
    background: url('../../../../assets/icons/ic-comment.svg')center  no-repeat;
}
.ResultsItemWorker__comment--width {
    position: relative;
    width: 12px;
    height: 12px;
    margin: 0 8px;
}
.ResultsItemWorker__comment--modal {
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 217px;
    padding: 0 20px;
    border: 1px solid #E34A4E;
    background: white;
    border-radius: 4px;
}
.ResultsItemWorker__comment--modal-text {
    color: #4D525B;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
}
.ResultsItemWorker__comment:hover {
    background: url('../../../../assets/icons/ic-comment-active.svg')center  no-repeat;
}

.ResultsItemWorker {
    /*max-width: 1170px;*/
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    background: white;
    border-bottom: 1px solid #828282;
    cursor: pointer;
    height: 80px;
    padding: 0 20px ;
}
.ResultsItemWorker:hover {
    box-shadow: 1px 0 10px rgba(0, 0, 0, 0.35);
    z-index: 2;
}
.ResultsItemWorker--last {
    border-radius: 0 0 8px 8px;
    border: none;
}
.ResultsItemWorker__statusDateBlock {
    /* margin-left: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.ResultsItemWorker__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #4D525B;
    max-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
/* .ResultsItemWorker__text--workerName {
    width: 140px;
}
 */
.ResultsItemWorker__text--subject {
    width: 80%;
} 
/* .ResultsItemWorker__text--department {
    width: 180px;
}
.ResultsItemWorker__text--specialistName {
    width: 180px;
}  */



.ResultsItemWorker__status {
    width: 110px;
    height: 16px;
    border-radius: 11px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    user-select: none;
    margin: 6px 0 0 0;
}
.ResultsItemWorker__workerIdBlock, .ResultsItemWorker__subjectBlock, .ResultsItemWorker__regionBlock, .ResultsItemWorker__departmentBlock  {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
/* .ResultsItemWorker__workerIdBlock {
    flex-basis: 14.5%;
} */
/* .ResultsItemWorker__subjectBlock {
    flex-basis: 15%;
} */
/* .ResultsItemWorker__specialistIdBlock {
    margin-right: 45px;
} */
.ResultsItemWorker__id {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #949494;
    margin: 0 0 6px 0;
}
.ResultsItemWorker__resultBlock {
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: flex-start;
}
.ResultsItemWorker__resultBlock--icons {
    position: relative;
    display: flex;
    align-items: center;
}
.ResultsItemWorker__icon--widthLikeDislike {
    width: 24px;
    height: 44px;
    margin-left: 30px;
}
.ResultsItemWorker__icon--like {
    background: url('../../../../assets/icons/like-active.svg')center / contain no-repeat;
}
.ResultsItemWorker__icon--dislike {
    background: url('../../../../assets/icons/icon-dislike__active.svg')center / contain no-repeat;
}
.seeDetailsBtn-text {
    font-family: Source Sans Pro;
    font-style: normal;
    position: relative;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.01em;
    display: none;
    color: #FFFFFF;
    user-select: none;
    /*margin-bottom: 20px;*/
}
.ResultsItemWorker > .seeDetailsBtn {
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 15%;
    right: 0;
    height: 80px;
    box-sizing: border-box;
    z-index: 1;
    background: linear-gradient(to right, transparent 50%, #C53437 16%, #E34A52 100%, #E34A4E 16%) left;
    background-size: 200%;
    transition: .25s ease-out;
}
.ResultsItemWorker:hover .seeDetailsBtn-text {
    display: block;
    
}
.ResultsItemWorker:hover .seeDetailsBtn {
    background-position: right;
}

