.access_info{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #C0C0C0;
    text-align: center;
}

.flex_row{
    display: flex;
    justify-content: space-between;
}

.commentResponse{
    /* padding: 20px 30px;
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    border-radius: 4px; */
}

.comment_wrapper{
    margin: 30px;
}

.moreInfo{
    color: #c0c0c0;
    font-size: 12px;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: right;
    border: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3), white 80%);
    align-self: flex-end;
    margin: 10px 0;
}
.block__checked{
    display: flex;
    align-items: center;
}
.WaitingForChat{
    background: #FFFFFF;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.35);
    border-radius: 0px 0px 4px 4px;
    text-align: center;
    padding: 15px 0;
    width: 100%;
    border: 0;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #4D525B;
    user-select: none;
}