.VCC {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    min-height: 120px;
    border-radius: 8px;
    padding-left: 15px;
    cursor: pointer;
}

.VCC-logo {
    width: 130px;
    height: 64px;
    background: url('../../../assets/images/vcc_logo.svg')left no-repeat;
    background-size: contain;
}
.VCC .classification {
    display: flex;
    flex-direction: column;
    width: 117px;
    padding-left: 25px;
    margin-right: 20px;
    border: 1px none #BDBDBD;
    height: 75px;
    border-left-style: solid;
    justify-content: center;
    
}
.VCC .classification p {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #BDBDBD;
}
.fill-inv:hover .vksHoverLogo-inv{
    visibility: visible;
}
.fill-inv .vksHoverLogo-inv {
    visibility: hidden;
    width: 160px;
    height: 40px;
    margin-right: 25px;
    background: url('../../../assets/logos/Asista_VKS_full_logo.svg')center no-repeat;
    background-size: contain;
}

@media (max-width: 1280px) {
    .VCC {
        background-size: 170px;
        min-height: 120px;
    }
}