.AuthenticationModal {
    width: 394px;
    min-height: 300px;
    margin: 8vh auto 0;
    background: white;
    border-radius: 8px;
    padding: 46px 42px 25px 42px;
}
.AuthenticationModal__logo {
    width: 204px;
    height: 97px;
    margin: 0 auto;
    background: url('../../../assets/images/logo_with_text.svg')center / contain no-repeat;
}