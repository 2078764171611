.AttachedFile{
    background: #FFFFFF;
    border: 1px solid #C0C0C0;
    border-radius: 4px;
    padding: 5px 35px 5px 8px;
    position: relative;
    text-decoration: none;
    width: 50%;
    cursor: pointer;
}

.AttachedFile__content{
    max-width: 30%;
    overflow: hidden;
}
.AttachedFile__content_name{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #4B4C4D;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}
.AttachedFile__content_info{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #989898;
    text-transform: uppercase;
}