.BIFilters{
    display: flex;
    gap: 15px;
    margin: 25px 0;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;

    color: #4D525B;
}
.BIFilters__filtername{
    display: flex;
    padding: 2px 8px;
    background: #FFFFFF;
    border-radius: 11px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #333333;
}

.BIFilters__deleteFilters{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    cursor: pointer;
    color: #4D525B;

}