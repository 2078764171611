.FunctionCard{
    background: #FFFFFF;
    box-shadow: 2px 4px 14px rgba(77, 82, 91, 0.27);
    border-radius: 8px;
    min-height: 180px;
    width: 226px;
    margin: 0 15px;
    color: #828282;
}
.FunctionCard:hover{
    color: #000000;
    cursor: pointer;
}
.FunctionCard__info{
    padding: 17px 40px;
}

.FunctionCard__title{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.01em;
}
.FunctionCard__text{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.01em; 
}

.FunctionCard__bar{
    box-sizing: border-box;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    border: 3px;
    background: #C0C0C0;
    height: 26px;
}
/* 
.FunctionCard__green:hover .FunctionCard__green{
} */
.FunctionCard__green:hover .FunctionCard__bar{
    background-color: #3CC13B;
}

.FunctionCard__yellow:hover .FunctionCard__bar{
    background-color: #F3BB1C;
}

.FunctionCard__dark:hover .FunctionCard__bar{
    background-color: #4B4C4D;
}