.faq-return {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 15px;
    right: -55px;
}

.prev-go-icon, .prev-go-text {
    font-style: normal;
    font-weight: 700;
}

.prev-go-icon {
    font-size: 26px;
    color: #C53036;
}

.prev-go-text {
    font-size: 14px;
    color: #4D525B;
}