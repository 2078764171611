.Red_undefined{
    width: 100%;
    min-height: 187px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #E34A4E;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 25px;
    letter-spacing: 0.01em;
    border-radius: 10px;
    color: #FFFFFF;
    margin-bottom: 128px;

}

.AlertInfo, .AlertInfo h3{
    font-size: 20px;
}

.AlertContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 61px;
    margin-bottom: 293px;
    
}

.AlertIcon{
    max-width: 88px;
    max-height: 88px;
}
@media (max-width: 1280px){
.Red_undefined {
    min-height: 150px;
}
}


.UndefinedIDAlert{
    display: flex;
    flex-direction: column;
}
.AlertInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
   
}
.AlertInfo_text{
    text-align: center;
}

.AlertInfo_title{
    text-align: center;
}