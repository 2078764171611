body {
	top: 0 !important;
	margin: 0;
	font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #f3f6f9;
}
*{
	box-sizing: border-box;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.TranslatePage{
	font-size: 14px;
	color: #747474;
	cursor: pointer;
	margin: 10px 0;
}
.notranslate{
	user-select: none;
	-webkit-user-select: none;
}
.goog-logo-link{
	display:none !important;
 }
.goog-te-gadget{
	color:transparent!important;
}
.goog-te-combo{
	padding: 3px 20px;
	border-radius: 3px;
	border: 0;
}
.skiptranslate span{
	display: none !important;
}
.swal2-html-container{
	overflow: unset !important;
}
.app{
	position: relative;
	min-height: 100vh;
}
.app_bg{
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background-image: url("./assets/images/winter_bg.svg");
	background-repeat: no-repeat;
	background-size: cover;
	opacity: .15;
	z-index: -1;
}
.App__utils{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 10px;
	padding: 10px 0 20px 0;
}
.App__notifications{
	padding: 7px;
	border-radius: 4px;
	cursor: pointer;
	background-color: #fff;
	border: 1px solid #C0C0C0;
	color: #C0C0C0;
	transition: all .3s ease;
}
.App__notifications:hover{
	cursor: pointer;
	background-color: #fff;
	border: 1px solid #E34A4E;
	color: #E34A4E;
}
.pointer{
	cursor: pointer;
}