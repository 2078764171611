.AddInput{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #00ACCD;
    cursor: pointer;
    background: none;
    border: 0;
}