.TranslationsForm {
    display: flex;
    flex-direction: column;
}
.TranslationsForm-manipulations {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.TranslationsForm-manipulations .translation {
    width: 325px;
    height: 205px;
    /*background-color: #C4C4C4 !important;*/
}
.TranslationsForm-manipulations .start {
    width: 200px;
    height: 75px;
    background: #4FC6E0;
    border-radius: 4px;
    border: none;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
}
.TranslationsForm-manipulations .start:hover {
    cursor: pointer;
    background-color: #37adc4;
}

.TranslationsForm-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 25px;
}

.TranslationsForm-body .rooms {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 46%;
    height: 110px;
    background: #ffffff;
    /* блок с тенью */
    margin-bottom: 20px;
    box-shadow: 2px 4px 14px rgba(77, 82, 91, 0.27);
    border-radius: 8px;
}
.TranslationsForm-body .rooms > * {
    margin-left: 25px;
}

.TranslationsForm-body .rooms .icon, .addIcon {
    width: 60px;
    height: 60px;
    background: #E34A4E;
    border-radius: 7px;
}

.titlesAndContents {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    margin-top: 20px;
}
.TranslationsForm-body .rooms .title, .addTitle {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #4D525B;
}

.TranslationsForm-body .rooms .content {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;

    color: #4D525B;
}

.TranslationsForm-body .rooms .more {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}
.TranslationsForm-body .rooms .more > * {
    border-radius: 50%;
    background: #C4C4C4;
    width: 7px;
    height: 7px;
    margin-bottom: 5px;
}
.addIcon {
    background: #D7D7D7;
    border-radius: 7px;
    border: none;
    font-size: 50px;
    color: #FFF;
    font-weight: bold;
}
.addIcon:hover {
    cursor: pointer;
    background-color: #e3e3e3;
}
.addTitles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25px;
    width: 50%;
    height: 100%;
    margin-top: 20px;
}
.addTitle {
    font-size: 16px;
}

.TranslationsForm-footer {
    display: flex;
    flex-direction: column;
}
.TranslationsForm-footer .history {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #4D525B;
}

@media (max-width: 1320px) {
    .TranslationsForm-footer .empty {
        font-size: 16px;
    }
}

.TranslationsForm-footer .translationTable {
    width: 100%;
    border: solid 1px #C0C0C0;
}

.empty {
    position: relative;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #BDBDBD;
    bottom: 175px;
    left: 30%;
}
.translationTable .first {
    height: 30px;
}

.translationTable .headers {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
/* identical to box height */

    letter-spacing: 0.01em;

    color: #828282;
}
.translationTable .body {
    height: 250px;
}