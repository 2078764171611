input[type="IconCheckbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
.IconCheckbox__group{

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;
    cursor: pointer;
    height: 120px;
    min-width: 150px;
}
.IconCheckbox {
    cursor: pointer;
    display: inline-block;
    height: 20px ;
    width: 20px;
    background: #fff;
    border: 2px solid #D7D7D7;
    border-radius: 3px;

}
 
.IconCheckbox_active{
    width: 12px;
    height: 12px;
    border: 2px solid #4FC6E0;
    border-radius: 1px;
    margin: 2px;
    background-color: #4FC6E0;
}

.IconCheckbox__label{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #333333;
}

.IconCheckbox__icon{
    width: 50px;
    height: 50px;
    object-fit: contain;
}