.FAQ-body_block {
    display: flex;
    flex-direction: row;
    padding: 50px 10px 50px 50px;
}

.body_inner {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-right: 50px;
}

.body_inner::-webkit-scrollbar {
    width: 20px;
    height: 90%;
    background-color: #F3F6F9;
    border: 20px transparent;

    left: 63.5%;
}
.body_inner::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px #00ACCD;
    right: 8px;
    min-width: 20px;
    border-radius: 20px;
    height: 140px;
    background-color: #00ACCD;
}

.body_inner::-webkit-scrollbar-track{
    position: fixed;
    width: 4px;
    height: 90%;
    box-shadow: inset 0px 0px 0px 8px #4fc6e0;
    background-color: #F3F6F9;
    left: 63.5%
}
.show {
    animation: faq-block-show ease-in-out .25s;
}

