.ChatMessage{
    display: flex;
    flex-direction: column;
    gap: 5px;

}
.ChatMessage__user{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.ChatMessage__user_img{
    position: absolute;
    right: calc(100% + 10px);
}
.ChatMessage__img{
    max-width: 50%;
    height: auto;
    cursor: pointer;
}
.ChatMessage__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ChatMessage__loader {
    width: 10px;
    height: 10px;
    border: 1px solid black;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 